import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import esLocale from 'date-fns/locale/es'

import {
  AutocompleteContainer,
  OptionCheckbox,
  OptionDisplay,
  OptionTextField,
  UserDocumentCreator,
  UserDocumentsList,
} from '../components'
import {
  USER_DOCUMENTS_QUERY,
  USER_DOCUMENT_TYPE_LABELS,
} from '../queries'
import { toISO8601DateTime } from '../services'

import type {
  UserDocumentsData,
  UserDocumentsVars,
} from '../queries'

const OPTIONS = Object.entries(USER_DOCUMENT_TYPE_LABELS)

export const DocumentsView = () => {
  const defaultDate = new Date()
  defaultDate.setHours(0, 0, 0, 0)

  const [endDate, setEndDate] = React.useState(defaultDate)
  const [startDate, setStartDate] = React.useState(defaultDate)
  const [documentTypes, setDocumentTypes] = React.useState<string[]>([])

  const { loading, data, refetch } =
    useQuery<UserDocumentsData, UserDocumentsVars>(USER_DOCUMENTS_QUERY, {
      variables: {
        endDate: toISO8601DateTime(endDate),
        startDate: toISO8601DateTime(startDate),
      },
    })

  const userDocuments = data?.userDocuments || []

  const filteredUserDocuments = (documentTypes.length > 0)
    ? userDocuments.filter((userDocument) => documentTypes.includes(userDocument.documentType))
    : userDocuments

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
        <Grid
          container
          spacing={3}
        >
          <AutocompleteContainer>
            <DatePicker
              disableFuture
              value={startDate}
              inputFormat='yyyy/MM/dd'
              onChange={(newValue) => {
                const safeValue = newValue || new Date()
                safeValue.setHours(0, 0, 0, 0)
                setStartDate(safeValue)

                if (safeValue > endDate) {
                  setEndDate(safeValue)
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label='Filtrar por fecha inicial'
                  fullWidth
                />
              )}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <DatePicker
              disableFuture
              value={endDate}
              inputFormat='yyyy/MM/dd'
              onChange={(newValue) => {
                const safeValue = newValue || new Date()
                safeValue.setHours(0, 0, 0, 0)
                setEndDate(safeValue)

                if (safeValue < startDate) {
                  setStartDate(safeValue)
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label='Filtrar por fecha final'
                  fullWidth
                />
              )}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <Autocomplete
              disableCloseOnSelect
              multiple
              options={OPTIONS}
              getOptionLabel={(option) => option[1]}
              onChange={(_event, newValue) => {
                setDocumentTypes(newValue.map((option) => option[0]))
              }}
              renderOption={(props, option, { selected }) => (
                <OptionDisplay {...props}>
                  <OptionCheckbox checked={selected} />
                  {option[1]}
                </OptionDisplay>
              )}
              renderInput={(params) => (
                <OptionTextField
                  name='documentType'
                  label='Filtrar por tipo de documento'
                  params={params}
                />
              )}
              disablePortal
            />
          </AutocompleteContainer>
          <UserDocumentsList
            loading={loading}
            documents={filteredUserDocuments}
          />
        </Grid>
      </LocalizationProvider>
      <UserDocumentCreator refetch={refetch} />
    </Box>
  )
}
